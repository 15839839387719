jQuery(document).ready(function ($) {
  const pillButtons = document.querySelectorAll("#v-pills-tab button");

  if (pillButtons && window.innerWidth < 600) {
    pillButtons.forEach((btn) => {
      btn.addEventListener("click", (_, index) => {
        const scrollToBox = document.querySelector(".scroll-to-box");

        const distanceToScroll =
          window.scrollY + scrollToBox.getBoundingClientRect().top;

        window.scrollTo({
          top: distanceToScroll,
          behavior: "smooth",
        });
      });
    });
  }

  const professionBoxes = document.querySelectorAll(".profession .title");

  if (professionBoxes && window.innerWidth < 600) {
    professionBoxes.forEach((box) => {
      box.addEventListener("click", () => {
        const scrollToBox = document.querySelector(".scroll-to-box-profession");

        const distanceToScroll =
          window.scrollY + scrollToBox.getBoundingClientRect().top;

        window.scrollTo({
          top: distanceToScroll,
          behavior: "smooth",
        });
      });
    });
  }

  const swiper = new Swiper(".swiper", {
    loop: true,
    autoplay: {
      delay: 4500,
      disableOnInteraction: false,
    },
    watchOverflow: true,
    slidesPerView: 1,
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  const typesofemployers_swiper = document.querySelector(
    ".typesofemployers-swiper"
  );

  if (typesofemployers_swiper) {
    new Swiper(".typesofemployers-swiper", {
      loop: true,
      slidesPerView: 3,
      spaceBetween: 60,
      navigation: {
        nextEl: ".types-swiper-button-next",
        prevEl: ".types-swiper-button-prev",
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 15,
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
      },
      // Navigation arrows
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
    });
  }

  const swiperNews = new Swiper(".swiper-news", {
    loop: false,

    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    slidesPerView: 4,
    spaceBetween: 30,
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next1",
      prevEl: ".swiper-button-prev1",
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 15,
      },
      // when window width is >= 480px
      600: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1100: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      1560: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
    },
  });
  var header = document.querySelector("header");

  // Pratimo promene u skrol poziciji
  window.addEventListener("scroll", function () {
    // Dohvatamo trenutnu skrol poziciju
    var scrollPosition = window.scrollY;

    // Ako je skrol pozicija veća od 0, dodajemo klasu "colored"
    if (scrollPosition > 0) {
      header.classList.add("colored");
    } else {
      // Ako je skrol pozicija 0, uklanjamo klasu "colored"
      header.classList.remove("colored");
    }
  });
  function toggleContent(element) {
    element.classList.toggle("active");
  }

  // Dohvatanje svih elemenata s klasom "contentlinks"
  var contentLinkElements = document.querySelectorAll(".contentlinks");

  // Dodavanje "click" događaja na svaki element s klasom "contentlinks"
  contentLinkElements.forEach(function (element, index) {
    element.addEventListener("click", function () {
      // Uklanjanje klase "active" sa svih elemenata
      contentLinkElements.forEach(function (el) {
        el.classList.remove("active");
      });

      // Dodavanje klase "active" samo kliknutom elementu
      element.classList.add("active");

      // Uklanjanje klase "active" sa svih "row" elemenata
      var rows = document.querySelectorAll(
        ".title.col-sm-12.col-md-6.col-lg-6.col-xl-3.d-flex.flex-column .row"
      );
      rows.forEach(function (row) {
        row.classList.remove("active");
      });

      // Dodavanje klase "active" samo "row" elementu pripadajućem kliknutom "contentlinks" elementu
      var row = rows[index];
      row.classList.add("active");
    });
  });

  // Provera da li je element prisutan na stranici
  const section = document.querySelector(".langue-row");
  if (section !== null) {
    // Definisanje funkcije inViewport
    function inViewport(element) {
      const scrolled = window.scrollY;
      const windowHeight = window.innerHeight;
      const elementDistanceFromTop = element.getBoundingClientRect().top;
      if (elementDistanceFromTop - windowHeight < 0) {
        element.classList.add("animated");
      }
    }

    // Dodavanje događaja scroll
    document.addEventListener("scroll", () => {
      inViewport(section);
    });
  }

  (function () {
    // Funkcija za obradu klika na .bar (otvaranje/zatvaranje mobilnog menija)
    function handleMobileMenuClick() {
      const mobileMenu = document.querySelector(".mobile-menu");
      const hamburgerMenu = document.querySelector(".hamburger-menu");

      if (mobileMenu.classList.contains("active")) {
        mobileMenu.classList.remove("active");
        hamburgerMenu.querySelector(".bar").classList.remove("animate");
      } else {
        mobileMenu.classList.add("active");
        hamburgerMenu.querySelector(".bar").classList.add("animate");
      }
    }

    // Slušač događaja za otvaranje/zatvaranje mobilnog menija kada se klikne na .bar
    document
      .querySelector(".hamburger-menu")
      .addEventListener("click", handleMobileMenuClick);

    // Slušač događaja za zatvaranje mobilnog menija kada se klikne izvan njega
    document.addEventListener("click", function (event) {
      const mobileMenu = document.querySelector(".mobile-menu");
      const hamburgerMenu = document.querySelector(".hamburger-menu");

      if (
        mobileMenu.classList.contains("active") &&
        !mobileMenu.contains(event.target) &&
        !hamburgerMenu.contains(event.target)
      ) {
        mobileMenu.classList.remove("active");
        hamburgerMenu.querySelector(".bar").classList.remove("animate");
      }
    });
  })();

  $("li.pll-parent-menu-item").hover(
    function () {
      $(this).addClass("active");
    },
    function () {
      $(this).removeClass("active");
    }
  );
  $(document).ready(function () {
    $("#username-978").on("click", function () {
      // Selektujemo element <div> sa klasom 'um-field-label' koji je sledeći sibling od input polja
      var labelElement = $(this).next(".um-field-label");

      // Dodajemo klasu 'Custom-class' na pronađeni element
      if (labelElement.length > 0) {
        labelElement.addClass("Custom-class");
      }
    });
  });

  $('.frm_conf_field input[type="email"]').on("paste", function (e) {
    e.preventDefault();
  });

  // Onemogući autocomplete za sva polja sa klasom "reg" i "mails"
  $('.frm_conf_field input[type="email"]').attr("autocomplete", "none");

  const popupOverlay = document.querySelector(".popup-overlay");
  const popupContainer = document.querySelector(".popup-container");
  const closePopupButton = document.getElementById("close-popup");

  function openPopup() {
    if (popupOverlay && popupContainer) {
      popupOverlay.style.display = "flex";
      setTimeout(() => {
        popupContainer.style.opacity = "1";
        popupContainer.style.transform = "scale(1)";
      }, 100);
    }
  }

  function closePopup() {
    if (popupContainer) {
      popupContainer.style.opacity = "0";
      popupContainer.style.transform = "scale(0.8)";
      setTimeout(() => {
        if (popupOverlay) {
          popupOverlay.style.display = "none";
        }
      }, 300);
    }
  }

  // Automatically open the popup when the page loads
  window.addEventListener("load", openPopup);

  if (closePopupButton) {
    closePopupButton.addEventListener("click", closePopup);
  }


  const languageCode = window.location.pathname.includes('/sr/') ? 'sr' : 'other'; // Promenite 'other' prema potrebi

  // Provera jezika i postavljanje odgovarajućeg teksta
  if (languageCode === 'sr') {
    // Promeni tekst ako je jezik srpski
    $('.next.page-numbers').text('Sledeće');
  }


  $(document).on('frmFormComplete', function (event, form, response) {
    // Dodajemo #message na kraj URL-a
    window.location.hash = '?message=success';
  });
  $(document).ready(function () {
    // Postavite trenutni jezik (na primer, en za engleski, sr za srpski, de za nemački, itd.)
    var currentLanguage = 'en';

    // Funkcija za postavljanje vrednosti u zavisnosti od jezika
    function setButtonValue(language) {
      var $submitButton = $(".is-search-submit");

      if (language === 'en') {
        $submitButton.val("Search");
      } else if (language === 'sr') {
        $submitButton.val("Pretraži");
      } else if (language === 'de') {
        $submitButton.val("Suchen");
      } else if (language === 'ru') {
        $submitButton.val("Искать");
      } else if (language === 'fr') {
        $submitButton.val("Rechercher");
      } else if (language === 'es') {
        $submitButton.val("Buscar");
      } else if (language === 'tr') {
        $submitButton.val("Arama");
      } // Dodajte uslove za ostale jezike
    }

    // Pozovite funkciju za postavljanje vrednosti na osnovu trenutnog jezika
    setButtonValue(currentLanguage);

    // Promenite jezik kad korisnik odabere drugi jezik
    $('#language-selector').on('change', function () {
      currentLanguage = $(this).val();
      setButtonValue(currentLanguage);
    });
  });

  $('.frm_forms').each(function () {
    var inputFields = $(this).find('input[type="text"], textarea,input[type="email"],input[type="tel"]');

    inputFields.each(function () {
      // Postavite maksimalnu dužinu na 5 karaktera
      $(this).attr('maxlength', 300);
    });
  });
});
